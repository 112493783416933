.browser div img{
  height: 50px;
} 
.browser {
  justify-content: space-between;
}
.copied{
  position: absolute;
  display: flex;
  justify-content: center;
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;
}
.copied h1{
  font-size: 50px;
  color:green;
  opacity:80%;
}
*{
padding: 0;
margin: 0;
box-sizing: border-box;
}
.url{
padding-top: 7px;
padding-bottom: 7px;
background-color: white;
border: 1px solid gray;
text-align: center;
border-radius: 100px;
}
.urlbox{
padding: 10px 0px;

}
.copy-btn{
padding: 10px 100px;
/* background-color: #1da1f2; */
cursor: pointer;
color: white;
font-weight: bold;
border: 0;
border-radius: 5px;

}

.add-copy-btn{
  padding: 10px 120px;
}


body{
background-color: #f8fafc;
}
.s1{
background-color: #f8f55b;
}
.s2{
height: 100px;display: flex;justify-content: center;
}
.s3{
color: #202013;text-align: center;
}
.s4{
padding:20px 0
}
.s5{
text-align: center;margin-left: 2%;margin-right: 2%;
}
.s6{
height: 3px;background-color:#acaca6
}
.s7{
padding: 20px 0;margin-right: 5%;margin-left:5%
}
.s8{
height:1px;background-color:gray
}
.s9{
margin-top: 5px
}
.s10{
margin-left: 2%;margin-right:2%
}
.s11{
display: flex;justify-content:center 
}
.s12{
padding: 10px 0;margin-left: 2%;margin-right:2%;
}
.s13{
margin-right: 5%;margin-left:5%
}
.s14{
display: flex;
}
.s15{
display: flex;margin-top:10px;
}
.s16{
display: block;text-align:center;padding: 10px 0;
margin-left: 5%;
margin-right: 5%;

}
.message{
  background-color: #f8fafc;
  width: 80%;
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: auto;
  padding: 5px 10px;
  border-radius: 10px;

}
.arrow{
  position: absolute;
 
  font-size: 510px;
  width: 50px;
  margin: auto;
  left: 0;
  right: 0;
  
  
}
.rel-arrow{
  position: relative;
}